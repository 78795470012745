<template>
  <transition
    name="expand"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave">
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'TransitionExpand',

  props: {
    height: {
      type: String,
      required: false,
      default: 'auto',
    },
    fixedEntryHeight: {
      type: String,
      required: false,
      default: null,
    },
    linear_padding: {
      type: String,
      required: false,
      default: '',
    },
    linear_margin: {
      type: String,
      required: false,
      default: '',
    },
  },

  methods: {
    enter(element) {
      element.style.height =
        this.fixedEntryHeight || `${element.scrollHeight}px`;
      element.style.opacity = 1;
      if (this.linear_padding !== '') {
        element.style.paddingTop = this.linear_padding;
        element.style.paddingBottom = this.linear_padding;
      }
      if (this.linear_margin !== '') {
        element.style.marginTop = this.linear_margin;
        element.style.marginBottom = this.linear_margin;
      }
    },

    afterEnter(element) {
      element.style.height = this.height;
    },

    leave(element) {
      element.style.height = `${element.scrollHeight}px`;
      setTimeout(() => {
        element.style.height = 0;
        element.style.opacity = 0;
        if (this.linear_padding !== '') {
          element.style.paddingTop = 0;
          element.style.paddingBottom = 0;
        }
        if (this.linear_margin !== '') {
          element.style.marginTop = 0;
          element.style.marginBottom = 0;
        }
      }, 50);
    },
  },
};
</script>

<style scoped>
* {
  will-change: height, padding-top, padding-bottom, margin-top, margin-bottom,
    opacity;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.expand-enter-active,
.expand-leave-active {
  transition: height 0.2s ease-in-out, padding-top 0.2s ease-in-out,
    padding-bottom 0.2s ease-in-out, margin-top 0.2s ease-in-out,
    margin-bottom 0.2s ease-in-out, opacity 0.2s ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  opacity: 0;
}
</style>
